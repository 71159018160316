import { computed } from "vue";
import { useAffiliateStore } from "../../stores/affiliate";

const defaultLinks = [
  {
    url: "",
    text: "Services",
    childLinks: [
      {
        url: "/get-started/comprehensive-blood-work",
        text: "Comprehensive Bloodwork",
      },
      { url: "/health/beyond-stem-cells", text: "Cell Therapy" },
      { url: "/product/semaglutide", text: "Weight Loss" },
      {
        url: "/health/peptide-therapy",
        text: "Peptide Therapy",
      },
      { url: "/health/iv-hydration", text: "IV Hydration" },

      { url: "/shop/products?type=health-tests", text: "Health Tests" },
      { url: "/product/brain-health", text: "Brain Health" },
      { url: "/health/hair-loss", text: "Hair Loss" },
      {
        url: "/health/skin-rejuvenating-treatments",
        text: "Skin Rejuvenating Treatments",
      },
      {
        url: "/health/hormone-optimization",
        text: "Men's Hormone Optimization",
      },
      {
        url: "/health/womens-hormone-therapy",
        text: "Women's Hormone Optimization",
      },
    ],
  },
  {
    url: "",
    text: "About",
    childLinks: [
      { url: "/company/about-us", text: "Company" },
      { url: "/company/meet-the-team", text: "Team" },
      // { url: "/company/meet-our-providers", text: "Providers" },
      { url: "/company/testimonials", text: "Testimonials" },
      { url: "/company/locations", text: "Locations" },
      { url: "/blog", text: "Blog" },
      // { url: "/careers", text: "Careers" },
    ],
  },
  { url: "/shop", text: "Shop" },
  {
    url: "",
    text: "Support",
    childLinks: [
      { url: "/support/contact-us", text: "Contact" },
      { url: "/support/faq", text: "FAQ" },
      { url: "/support/quick-links", text: "Quick Links" },
    ],
  },
  { url: "/jre", text: "JRE Links" },
];

export default defaultLinks;

export function useLinks() {
  const affiliateStore = useAffiliateStore();
  const links = computed(() => {
    if (affiliateStore.email) {
      return [
        ...defaultLinks,
        // intentional weird space character to prevent line break between the 2 first word
        {
          text: `My W2W`,
          childLinks: [
            { url: "/referral", text: affiliateStore.email },
            { text: "Logout", url: "/referral/logout" },
          ],
        },
      ];
    }
    return [
      ...defaultLinks,
      // intentional weird space character to prevent line break between the 2 first word
      { url: "/referral", text: "Refer a Friend" },
    ];
  });

  return links;
}
