import VueJwtDecode from "vue-jwt-decode";

/**
 * Determine if the token expiration time will occur before `seconds` elapses
 * @param {string} jwt
 * @param {number} seconds
 * @returns {boolean}
 */
export function isJWTValidForSeconds(jwt, seconds = 30) {
  const body = VueJwtDecode.decode(jwt);
  const now = Math.floor(new Date().getTime() / 1000);
  const hasIssuedAt = "iat" in body;
  const hasExpired = "exp" in body;
  const isAfterIssuedAt = hasIssuedAt && body.iat < now;
  const isBeforeExpired = hasExpired && now + seconds < body.exp;
  console.debug({
    now,
    body,
    hasIssuedAt,
    isAfterIssuedAt,
    hasExpired,
    isBeforeExpired,
  });
  return (!hasIssuedAt || isAfterIssuedAt) && (!hasExpired || isBeforeExpired);
}
