import { createApp } from "vue";
import { createPinia } from "pinia";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate"
import App from "./App.vue";
import router from "./router";
import "virtual:svg-icons-register";
import Vue3TouchEvents from "vue3-touch-events";
import { Tabs, Tab } from "vue3-tabs-component";
import * as Sentry from "@sentry/vue";
import FancyImage from "./components/FancyImage/FancyImage.vue";
import Hotjar from "@hotjar/browser";
import { VueMasonryPlugin } from "vue-masonry";

import "./assets/styles/base.scss";

const app = createApp(App);
const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);
const sentryEnabled = import.meta.env.VITE_APP_SENTRY_ENABLED === "true";

Sentry.init({
  app,
  enabled: sentryEnabled,
  environment: import.meta.env.VITE_APP_SENTRY_ENVIRONMENT,
  dsn: import.meta.env.VITE_APP_SENTRY_DSN,
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
    }),
    new Sentry.Replay(),
  ],
  enableTracing: true,
  // Performance Monitoring
  tracesSampleRate: 0.25, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.

  beforeSend(event, hint) {
    console.log(event, hint);
    return event;
  },
});

const siteId = import.meta.env.VITE_APP_HOTJAR_SITE_ID;
const hotjarVersion = 6;
Hotjar.init(siteId, hotjarVersion);

app.use(router);
app.use(Vue3TouchEvents);
app.use(pinia);

app.component("imgix", FancyImage);

// app.use(VueImgix, {
//   domain: "w2w-web-media.imgix.net",
//   defaultIxParams: {
//     // This enables the auto format imgix parameter by default for all images, which we recommend to reduce image size, but you might choose to turn this off.
//     auto: "format",
//   },
// });

app.component("VueTabs", Tabs);
app.component("VueTab", Tab);

app.mount("#app");

app.use(VueMasonryPlugin);
